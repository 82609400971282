import React from 'react';

import FileInfo from '../../components/FileInfo';
import useMessageAttachment from '../../pages/Chat/UserChat/MessageContent/useMessageAttachment';
import MessageAttachment from '../../stores/Attachment/MessageAttachment';
import MessageImageViewer from './MessageImageViewer';
import {AttachmentsNavigation} from './useMessageAttachmentsNavigation';
import VideoPlayer from '../../components/VideoPlayer';
import useAttachmentProgress from '../../pages/Chat/UserChat/MessageContent/useAttachmentProgress';

interface MediaViewerItemProps {
  rotateDeg: number | null;
  attachment: MessageAttachment;
  attachmentsNav: AttachmentsNavigation<MessageAttachment>;
  smallPlayer: boolean;
  toggleHideViewerControls(): void;
  toggleSmallPlayer(): void;
  toggleHideCloseButton(hide: boolean): void;
}

export const MediaViewerItem = ({
  attachment,
  rotateDeg,
  attachmentsNav,
  toggleHideViewerControls,
  toggleHideCloseButton,
  toggleSmallPlayer,
  smallPlayer,
}: MediaViewerItemProps) => {
  const {src, width, height, duration} = useMessageAttachment(attachment);
  const {isInProgress} = useAttachmentProgress(attachment);

  React.useEffect(() => {
    attachmentsNav.setDownloadUrl(src || '');
  }, [src, attachmentsNav]);

  if (attachment.isImage || attachment.isSticker || attachment.isDocumentImage) {
    return (
      <MessageImageViewer
        src={src}
        width={width}
        height={height}
        onUrlUpdate={(url) => attachmentsNav.setDownloadUrl(url)}
        rotateDeg={rotateDeg}
        transformerClassName="w-100-i h-100-i"
        retryOnError={true}
        loaderEnabled={true}
        reloadButtonEnabled={true}
      />
    );
  } else if (attachment.isDocument) {
    return (
      <div className="bg-background-primary position-absolute-center py-3 px-5" style={{height: 72, maxWidth: 360}}>
        <FileInfo attachment={attachment} />
      </div>
    );
  } else if (attachment.isVideo) {
    return (
      <VideoPlayer
        duration={duration}
        src={src}
        width={width || 0}
        height={height || 0}
        retryOnError={true}
        rotateDeg={rotateDeg}
        smallPlayer={smallPlayer}
        toggleSmallPlayerClick={toggleSmallPlayer}
        toggleHideViewerControls={toggleHideViewerControls}
        toggleHideCloseButton={toggleHideCloseButton}
        loading={isInProgress}
      />
    );
  }

  return null;
};

export default MediaViewerItem;